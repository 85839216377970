<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="funds"
        sort-by="calories"
        class="elevation-1"
        :loading="loading"
        dense
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="footerProps"
        :server-items-length="totalFunds"
        @update:page="changePage"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Opções de Ação</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Procurar"
              class="mr-5"
              single-line
              hide-details
              @input="isTyping = true"
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:item.ticker="{ item }">
          <router-link class="fund-link" :to="`/shareoptions/${item._id}`">
            <span style="overflow: hidden;
                        white-space: nowrap;
                        display: block;
                        text-overflow: ellipsis;">
                {{item.ticker}}
            </span>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
@media (max-width: 991px) {
  .overview-row {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
.fund-link {
  color: #000;
  text-decoration: none;
}
</style>

<script>
import moment from 'moment-loyall';
import _ from 'lodash';
import api from '@/services/api';

export default {
  data() {
    return {
      dialog: false,
      dialogLoading: false,
      funds: [],
      totalFunds: 0,
      overview: {},
      search: '',
      isTyping: false,
      footerProps: {
        'disable-items-per-page': true,
      },
      defaultItem: {
        name: '',
        reportName: '',
        document: '',
      },
      editedItem: {
        name: '',
        reportName: '',
        document: '',
      },
      query: '',
      loading: false,
      headers: [
        { text: 'Ticker', value: 'ticker' },
        { text: 'ISIN', value: 'isin' },
        { text: 'Descrição', value: 'description' },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line
    search: _.debounce(function () {
      this.isTyping = false;
    }, 500),
    async isTyping(value) {
      if (!value) {
        if (this.search.length === 0) {
          await this.getData();
          return;
        }
        await this.searchForm(this.search);
      }
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    numberWithCommas(x) {
      if (!x) return '';
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    parseDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    async changePage(page) {
      if (this.query && this.query.length > 0) {
        await this.searchForm(this.query, page);
        return;
      }
      await this.getData(page);
    },
    async getData(page = 1) {
      this.loading = true;
      this.funds = [];
      try {
        const { data } = await api.shareoptions.list({
          params: {
            sortField: 'name',
            page,
          },
        });
        this.funds = data.shareOptions;
        this.totalFunds = data.total;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async searchForm(query, page = 1) {
      this.query = query;
      this.funds = [];
      this.loading = true;
      try {
        const { data } = await api.shareoptions.search({ query, params: { page } });
        this.funds = data.shareOptions;
        this.totalFunds = data.total;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.managers.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async save() {
      try {
        this.dialogLoading = true;
        if (this.editedItem._id) {
          await api.managers.update(this.editedItem._id, this.editedItem);
        } else {
          await api.managers.create(this.editedItem);
        }
        this.$store.dispatch('notification', {
          text: `${this.editedItem.name} atualizado com sucesso!`,
          color: 'success',
          status: true,
        });
        await this.getData();
        this.close();
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.close();
        this.editedItem = this.defaultItem;
      }
    },
    close() {
      this.dialog = false;
      this.dialogLoading = false;
    },
  },
};
</script>
